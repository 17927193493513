<template>
  <div>
    <CDataTable
      :hover="hover"
      :striped="striped"
      :border="border"
      :small="small"
      :fixed="fixed"
      :items="items"
      :fields="is_new ? (discounts > 0 ? fields_with_actions : fields_with_actions_without_discounts) : (discounts > 0 ? fields : fields_without_discounts)"
      :items-per-page="small ? 10 : 5"
      :dark="dark"
      pagination
      addTableClasses="no-margin"
    >
      <template #sat_code="{item}"><td class="w100 text-center">{{ item.sat_code }}</td></template>
      <template #quantity="{item}"><td class="w100 text-center">{{ item.quantity | decimal }}</td></template>
      <template #unit="{item}"><td class="w100 text-center">{{ item.unit.unit_code }}<br />{{ item.unit.unit_name }}</td></template>
      <template #description="{item}"><td class="text-left" v-html="item.description.replace(/(?:\r\n|\r|\n)/g, '<br>')"></td></template>
      <template #price="{item}"><td class="w100 text-right">$ {{ item.price | currency }}</td></template>
      <template #discount="{item}"><td class="w100 text-right"  v-show="discounts > 0">$ {{ item.discount | currency }}</td></template>
      <template #taxes="{item}">
      <td class="w200">
        <div v-for="tax in item.taxes">
          <span style="float: right;">$ {{((item.quantity * item.price - item.discount) * tax.rate) | currency}}</span>
          {{ tax.name }}{{ tax.type == "RETENIDO" || tax.type == "RETENCION" ? " RET" : "" }} {{ tax.rate * 100 | decimal }}%
        </div>
      </td>
      </template>
      <template #total="{item}"><td class="w100 text-right">$ {{ item.total | currency }}</td></template>
      <template #actions="{item, index}">
        <td class="table_actions b2" v-if="is_new">
          <CButton color="warning" size="sm" @click="updateModal(item)"><CIcon name="cil-pencil"/></CButton>
          <CButton color="danger" size="sm" @click="deleteModal(item, index)"><CIcon name="cil-trash"/></CButton>
        </td>
      </template>
    </CDataTable>
    <CDataTable
      addTableClasses="no-margin"
      :header="false"
      :hover="hover"
      :striped="striped"
      :border="border"
      :small="small"
      :fixed="fixed"
      :fields="totals_fields"
      :items="discounts > 0 ? totals_items : totals_items_without_discounts"
    >
      <template #labels="{item}"><th class="w200 text-right" style="text-align: right !important;">{{ item.labels }}</th></template>
      <template #totals="{item, index}">
        <td class="w100 text-right">
          $ {{ (index == 0 ? subtotal : (index == 1 && discounts > 0 ? discounts : ((index == 2 && discounts > 0) ? subtotal - discounts : (index == 1 || (index == 3 && discounts > 0) ? transfered_taxes : (index == 2 || index == 4 && discounts > 0 ? withheld_taxes : total))))) | currency }}
        </td>
      </template>
      <template #letters="{item, index}"><td :rowspan="discounts > 0 ? 6 : 4" class="text-center" v-if="index == 0"><br>{{letters}}<span style="vertical-align: bottom;" v-if="comments != ''"><hr v-if="letters != ''"><div style="width: 100%; text-align: left;"><b>Observaciones: </b><br><pre>{{comments}}</pre></div></span></td></template>
      <template #actions="{item, index}">
        <td class="table_actions b2" v-if="is_new"></td>
      </template>
    </CDataTable>
    <ConceptModal ref="conceptModal" @updateConcept="updateProcess"></ConceptModal>
    <DeleteModal ref="deleteModal" @delete="deleteProcess"></DeleteModal>
    
  </div>
</template>

<script>
import ConceptModal from './modals/ConceptModal.vue'
import DeleteModal from '../global/DeleteModal.vue'
import ws from '../../services/invoices';
import store from '../../store'

export default {
  name: 'ConceptsTable',
  components: { ConceptModal, DeleteModal },
  props: {
    is_new: true,
    items: Array,
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean,
    letters: {
      type: String,
      default: ''
    },
    subtotal: 0,
    discounts: 0,
    transfered_taxes: 0,
    withheld_taxes: 0,
    total: 0,
    comments: ''
  },
  data() {
    return {
      fields: [
        {key: 'sat_code', label: 'Clave SAT'},
        {key: 'quantity', label: 'Cantidad'},
        {key: 'unit', label: 'Unidad'},
        {key: 'description', label: 'Concepto'},
        {key: 'price', label: 'Precio Unitario'},
        {key: 'discount', label: 'Descuento'},
        {key: 'taxes', label: 'Impuestos'},
        {key: 'total', label: 'Importe'},
      ],
      fields_with_actions: [
        {key: 'sat_code', label: 'Clave SAT'},
        {key: 'quantity', label: 'Cantidad'},
        {key: 'unit', label: 'Unidad'},
        {key: 'description', label: 'Concepto'},
        {key: 'price', label: 'Precio Unitario'},
        {key: 'discount', label: 'Descuento'},
        {key: 'taxes', label: 'Impuestos'},
        {key: 'total', label: 'Importe'},
        {key: 'actions', label: 'Acciones', class: 'actions' },
      ],
      fields_without_discounts: [
        {key: 'sat_code', label: 'Clave SAT'},
        {key: 'quantity', label: 'Cantidad'},
        {key: 'unit', label: 'Unidad'},
        {key: 'description', label: 'Concepto'},
        {key: 'price', label: 'Precio Unitario'},
        {key: 'taxes', label: 'Impuestos'},
        {key: 'total', label: 'Importe'},
      ],
      fields_with_actions_without_discounts: [
        {key: 'sat_code', label: 'Clave SAT'},
        {key: 'quantity', label: 'Cantidad'},
        {key: 'unit', label: 'Unidad'},
        {key: 'description', label: 'Concepto'},
        {key: 'price', label: 'Precio Unitario'},
        {key: 'taxes', label: 'Impuestos'},
        {key: 'total', label: 'Importe'},
        {key: 'actions', label: 'Acciones', class: 'actions' },
      ],
      toasts: [],
      item: false,
      totals_items: [
        {letters: '', labels: 'Subtotal', totals: '0'},
        {letters: '', labels: 'Descuentos', totals: '0'},
        {letters: '', labels: 'Subtotal', totals: '0'},
        {letters: '', labels: 'Impuestos Trasladados', totals: '0'},
        {letters: '', labels: 'Impuestos Retenidos', totals: '0'},
        {letters: '', labels: 'Total', totals: '0'}
      ],
      totals_items_without_discounts: [
        {letters: '', labels: 'Subtotal', totals: '0'},
        {letters: '', labels: 'Impuestos Trasladados', totals: '0'},
        {letters: '', labels: 'Impuestos Retenidos', totals: '0'},
        {letters: '', labels: 'Total', totals: '0'}
      ],
      totals_fields: [
        {key: 'letters', label: ''},
        {key: 'labels', label: ''},
        {key: 'totals', label: ''},
        {key: 'actions', label: ''}
      ]
    }
  },
  mounted: async function() {
    
  },
  methods: {
    updateModal (item) {
      this.$refs.conceptModal.editConceptModal(item);
      this.item = item;
    },
    deleteModal (item, index) {
      this.$refs.deleteModal.show("Eliminar Concepto", index, item.description);
      this.item = item;
    },
    async updateProcess(tab, data, taxes) {
      data.quantity = data.quantity.replaceAll(',', '');
      data.price = data.price.replaceAll(',', '');
      data.discount = data.discount.replaceAll(',', '');
      data.import = data.import.replaceAll(',', '');

      this.item.quantity = data.quantity;
      this.item.sat_code = data.fiscal_product;
      this.item.unit.unit_code = data.unit_code;
      this.item.unit.unit_name = data.unit_name;
      this.item.description = data.concept;
      this.item.price = data.price;
      this.item.discount = data.discount;
      this.item.tax_id = data.taxes;
      this.item.taxes = taxes.taxes;
      this.item.total = data.import;

      this.$emit("refresh");
    },
    async deleteProcess(index) {
      this.$emit("deleteConcept", index);
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
