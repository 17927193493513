<template>
  <div>
    <CRow>
      <CCol sm="12">
        <InvoicesView
          :id="id"
          :is_new="id > 0 && option != 'clone' ? false : true"
          :is_clone="option == 'clone' ? true : false"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import InvoicesView from '../components/invoices/InvoicesView.vue'
import ws from '../services/invoices';
 
export default {
  name: 'Invoice',
  components: { InvoicesView },
  data: function () {
		return {
      id: 0,
      option: 'draft'
    }
  },
  mounted: async function() {
      this.id = await this.$route.params.id;
      this.option = await this.$route.params.option;
  },
  methods: {

  }
}
</script>
