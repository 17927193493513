import axios from 'axios'

export default {
    get: () => axios.get('taxes'),
    store: (data) => axios.post('taxes/store', data),
    update: (data) => axios.put('taxes/'+data.id, data),
    delete: (id) => axios.delete('taxes/'+id, ),
    getTaxGroups: () => axios.get('taxes/groups'),
    storeTaxGroup: (data) => axios.post('taxes/groups/store', data),
    updateTaxGroup: (data) => axios.put('taxes/groups/'+data.id, data),
    deleteTaxGroup: (id) => axios.delete('taxes/groups/'+id, ),
    list: () => axios.get('taxes/list'),
    getLocalTaxes: () => axios.get('taxes/local'),
    getLocalTaxesNotUsed: (local_taxes) => axios.post('taxes/local', local_taxes),
    storeLocalTax: (data) => axios.post('taxes/local/store', data),
    updateLocalTax: (data) => axios.put('taxes/local/'+data.id, data),
    deleteLocalTax: (id) => axios.delete('taxes/local/'+id, ),
}