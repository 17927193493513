import axios from 'axios'

export default {
    get: () => axios.get('fiscal-products'),
    getProduct: (id) => axios.get('fiscal-products?id='+id),
    addSelectedProduct: (code) => axios.post('fiscal-products/store', {sat_code: code}),
    deleteSelectedProduct: (sat_code) => axios.delete('fiscal-products/'+sat_code),
    getTypes: () => axios.get('cat/products/types'),
    getSegments: (type_id) => axios.get('cat/products/segments?type_id='+type_id),
    getFamilies: (type_id, segment_id) => axios.get('cat/products/families?type_id='+type_id+'&segment_id='+segment_id),
    getClasses: (type_id, segment_id, family_id) => axios.get('cat/products/class?type_id='+type_id+'&segment_id='+segment_id+'&family_id='+family_id),
    getProducts: (type_id, segment_id, family_id, class_id) => axios.get('cat/products?type_id='+type_id+'&segment_id='+segment_id+'&family_id='+family_id+'&class_id='+class_id),
    getSearchProducts: (search, type_id, segment_id, family_id, class_id) => axios.get('cat/products?search='+search+'&type_id='+type_id+'&segment_id='+segment_id+'&family_id='+family_id+'&class_id='+class_id),
    getSelectedProducts: (search, type_id, segment_id, family_id, class_id) => axios.get('cat/products/selected?search='+search+'&type_id='+type_id+'&segment_id='+segment_id+'&family_id='+family_id+'&class_id='+class_id),
    clearSelectedProducts: () => axios.delete('fiscal-products/remove'),
}