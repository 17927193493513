<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="localTaxModal"
    >
      <CRow>
        <CCol md="12">
          <CForm>
            <CRow>
              <CCol :md="has_discount ? 8 : 12">
                <CSelect
                  label="Impuesto Local:"
                  :isValid="checkIfValid('local_tax_id')"
                  :value.sync="$v.form.local_tax_id.$model"
                  :options="localTaxesOptions"
                />
              </CCol>
              <CCol v-if="has_discount">
                <div role="group" class="form-group">
                  <label for="uid-d5jvmlyg2tv" class=""> Aplicar a: </label>
                  <select v-model="form.apply_to" class="form-control">
                    <option value="subtotal">Subtotal</option>
                    <option value="subtotal_with_discounts">Subtotal con Descuentos</option>
                  </select>
                </div>
              </CCol>
            </CRow>
          </CForm>
        </CCol>
      </CRow>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="store" :color="color">Agregar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required} from "vuelidate/lib/validators"
import general_ws from '../../../services/general';
import invoices_ws from '../../../services/invoices';
import taxes_ws from '../../../services/taxes';

import moment from 'moment';

const today = new moment();
const today2 = new moment();

export default { 
  name: 'LocalTaxModal',
  props: {
    has_discount: false
  },
  components: {

  },
  data () {
    return {
      localTaxModal: false,
      title: "Agregar CFDI Relacionado", 
      color: "info",
      isEdit: false,
      form: {},
      submitted: false,
      cfdis: [],
      cfdisOptions: [],
      localTaxes: [],
      localTaxesOptions: []
    }
  },
  mounted: async function() {
    let response1 = await invoices_ws.getUUIDsByType("XAXX010101000", this.type); 

    if(response1.type == "success"){
      this.cfdis = response1.data;

      this.cfdisOptions = await this.$parseSelectOptionsOrdered(this.cfdis, "uuid", "folio:uuid");
    }

    let response2 = await taxes_ws.getLocalTaxes(); 

    if(response2.type == "success"){
      this.localTaxes = response2.data;

      this.localTaxesOptions = await this.$parseSelectOptionsOrdered(this.localTaxes, "id", "name");
    }
  },
  methods: {
    async addLocalTaxModal (local_taxes) {
      this.localTaxModal = true;
      this.color = "info";
      this.title = 'Agregar Impuesto';
      this.isEdit = false;
      this.submitted = false;

      let response2 = await taxes_ws.getLocalTaxesNotUsed(local_taxes); 

      if(response2.type == "success"){
        this.localTaxes = response2.data;

        this.localTaxesOptions = await this.$parseSelectOptionsOrdered(this.localTaxes, "id", "name");
        
      }

      let apply_to = "subtotal";
      if(this.has_discount){
        apply_to = "subtotal_with_discounts";
      }

      if(this.localTaxes[0]){
        this.form = {
          local_tax_id: this.localTaxes[0].id,
          apply_to: apply_to
        }; 
      }
      else{
        this.form = {
          local_tax_id: "",
          apply_to: apply_to
        };
      }
    },
    async store () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;
        
        let local_tax = this.$findElementById(this.localTaxes, this.form.local_tax_id);

        local_tax.apply_to = this.form.apply_to;

        this.$emit("addLocalTax", local_tax);

        this.closeModal();
      }
    },
    closeModal () {
      this.localTaxModal = false;

      this.submitted = false
      this.$v.$reset()
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid)
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      local_tax_id: {
        required
      }
    }
  },
}
</script>