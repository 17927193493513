import axios from 'axios'

export default {
    get: () => axios.get('units'),
    store: (data) => axios.post('units/store', data),
    update: (data) => axios.put('units/'+data.id, data),
    delete: (id) => axios.delete('units/'+id),
    excel: () => axios.post('units/excel'),
    getSearchUnits: (search) => axios.get('cat/units?search='+search),
    getSelectedUnits: (search) => axios.get('cat/units/selected?search='+search),
    clearSelectedUnits: () => axios.delete('units/remove'),
    addSelectedUnit: (data) => axios.post('units/store', {code: data.code, name: data.name}),
    deleteSelectedUnit: (code) => axios.delete('units/'+code),
}