<template>
  <div>
    <CRow>
      <CCol sm="4">
       <CCard class="invoice-card">
        <CCardHeader color="info">
          Emisor
          <div class="card-header-actions">
            
          </div>
        </CCardHeader>
        <CCardBody>
          <b>{{invoice.emisor.rfc}}</b>
          <br />
          {{invoice.emisor.name}}
          <br />
          <br />
          {{invoice.emisor.regimen.code}} - {{invoice.emisor.regimen.name}}
        </CCardBody>
      </CCard>
      </CCol>
      <CCol sm="4">
       <CCard class="invoice-card">
        <CCardHeader color="info">
          <slot name="header">
            Receptor
            <div class="card-header-actions" v-if="is_new">
              <CButton class="btn btn-light" size="sm" @click="receptorModal(invoice.receptor)">Editar</CButton>
            </div>
          </slot>
        </CCardHeader>
        <CCardBody>
          <b>{{invoice.receptor.rfc}}</b>
          <br />
          {{invoice.receptor.name}} <span v-if="invoice.receptor.rfc == 'XEXX010101000' && invoice.receptor.identifier != undefined && invoice.receptor.identifier != ''">[Reg. Id: {{invoice.receptor.identifier}}]</span>
          <br />
          <br />
          {{invoice.receptor.uso_cfdi.code}} - {{invoice.receptor.uso_cfdi.name}}
        </CCardBody>
        <ReceptorModal ref="receptorModal" type="I" :zipcode="invoice.emisor.zipcode" @updateReceptor="updateReceptor"></ReceptorModal>
      </CCard>
      </CCol>
      <CCol sm="4">
       <CCard class="invoice-card">
        <CCardHeader color="info">
          <slot name="header">
            Información de la Factura
            <div class="card-header-actions" v-if="is_new">
              <CButton class="btn btn-light" size="sm" @click="informationModal(invoice.information)">Editar</CButton>
            </div>
          </slot>
        </CCardHeader>
        <CCardBody>
          <div class="card-header-actions text-right">
              <b>Fecha: </b>{{invoice.information.date | date}}
              <br /><br /><br />
              <b>Moneda: </b>{{invoice.information.currency.code}} <span v-show="invoice.information.currency.code != 'MXN'">({{invoice.information.currency.exchange_rate}})</span>
          </div>
          <b>Folio: </b>{{invoice.information.serial}}{{invoice.information.folio}}
          <br />
          <span v-if="invoice.information.uuid != ''">
            <b>Folio Fiscal: </b>{{invoice.information.uuid}}
          </span>
          <br />
          <b>Forma de Pago: </b>{{invoice.information.payment_form.code}} - {{invoice.information.payment_form.name}}
          <br />
          <b>Método de Pago: </b>{{invoice.information.payment_method.code}} - {{invoice.information.payment_method.name}}
        </CCardBody>
        <InformationModal ref="informationModal" type="income" @updateInformation="updateInformation"></InformationModal>
      </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
       <CCard class="invoice-concepts">
        <CCardHeader>
          <slot name="header">
            Conceptos
            <div class="card-header-actions">
              <CButton v-if="is_new && addendas.local_taxes" class="btn btn-success" size="sm" v-show="!has_local_taxes" @click="enableLocalTaxes()">Impuestos Locales</CButton>
              <CButton v-if="is_new" class="btn btn-outline-success" size="sm" v-show="!has_relations" @click="enableRelations()">CFDI Relacionados</CButton>
              <CButton v-if="is_new && concepts.length > 0" class="btn btn-outline-warning" size="sm" @click="addDiscountModal()">Agregar Descuento</CButton>
              <CButton v-if="is_new" class="btn btn-outline-info" size="sm" @click="addConceptModal()">Agregar Concepto</CButton>
              <span style="color: red;" v-if="!is_new && invoice.canceled == 1">CANCELADA</span>
              <span style="color: red;" v-if="invoice.cancelacion_status == 'En proceso'">EN PROCESO DE CANCELACIÓN</span>
            </div>
          </slot>
        </CCardHeader>
        <CCardBody>
          <ConceptsTable
            :items="concepts"
            :subtotal="subtotal"
            :discounts="discounts"
            :transfered_taxes="transfered_taxes"
            :withheld_taxes="withheld_taxes"
            :total="total"
            :letters="letters"
            :comments="invoice.information.comments"
            :is_new="is_new"
            hover
            striped
            border
            small
            fixed
            caption="Conceptos"
            @refresh="refreshConcepts"
            @deleteConcept="deleteConcept"
          />
          <ConceptModal ref="conceptModal" @addConcept="addConcept"></ConceptModal>
          <DiscountModal ref="discountModal" :has_discount="has_discount" @addDiscount="addDiscount" @deleteDiscount="deleteDiscount"></DiscountModal>
        </CCardBody>
      </CCard>
      </CCol>
    </CRow>
    <CRow v-show="has_local_taxes">
      <CCol sm="12">
       <CCard>
        <CCardHeader>
          <slot name="header">
            Impuestos Locales
            <div class="card-header-actions" v-if="is_new">
              <CButton class="btn btn-outline-dark" size="sm" @click="disableLocalTaxes()" v-show="has_local_taxes && local_taxes.length == 0">Cerrar</CButton>
              <CButton class="btn btn-outline-info" size="sm" @click="addLocalTaxModal(local_taxes)">Agregar Impuesto</CButton>
            </div>
          </slot>
        </CCardHeader>
        <CCardBody>
          <LocalTaxesTable
            :items="local_taxes"
            :is_new="is_new"
            :subtotal="Number(subtotal)"
            :discounts="Number(discounts)"
            hover
            striped
            border
            small
            fixed
            caption="Impuestos Locales"
            @refresh="refreshLocalTaxes"
            @deleteLocalTax="deleteLocalTax"
          />

          <LocalTaxModal ref="localTaxModal" :has_discount="has_discount" @addLocalTax="addLocalTax"></LocalTaxModal>
        </CCardBody>
      </CCard>
      </CCol>
    </CRow>
    <CRow v-show="has_relations">
      <CCol sm="12">
       <CCard>
        <CCardHeader>
          <slot name="header">
            CFDIs Relacionados
            <div class="card-header-actions" v-if="is_new">
              <CButton class="btn btn-outline-dark" size="sm" @click="disableRelations()" v-show="has_relations && relations.length == 0">Cerrar</CButton>
              <CButton class="btn btn-outline-info" size="sm" @click="addRelationModal(invoice.receptor.rfc, relations)">Agregar CFDI</CButton>
            </div>
          </slot>
        </CCardHeader>
        <CCardBody>
          <RelationsTable
            :items="relations"
            :is_new="is_new"
            :relation_type="relation_type"
            hover
            striped
            border
            small
            fixed
            caption="CFDIs Relacionados"
            @refresh="refreshRelations"
            @deleteRelation="deleteRelation"
          />

          <RelationModal ref="relationModal" type="I" :relation_type="relation_type" @addRelation="addRelation" :relationDisabled="relationDisabled"></RelationModal>
        </CCardBody>
      </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12" v-if="has_access">
        <CCard>
          <CCardBody class="text-left">
            <CButton class="btn btn-outline-danger" size="sm" @click="cancelInvoice()" v-if="!is_new && !invoice.canceled">Cancelar</CButton>
            <!-- <CButton class="btn btn-outline-danger" size="sm" @click="downloadCancelationPDF()" v-if="!is_new && invoice.canceled">Acuse de Cancelación</CButton> -->
            <CButton class="btn btn-outline-dark" size="sm" @click="clearInvoice()" v-if="is_new && concepts.length > 0">Limpiar</CButton>
            <div class="card-header-actions">
              <CButton color="default" size="sm" @click="cloneInvoice()" v-if="!is_new">Clonar</CButton>
              <CButton color="success" size="sm" @click="downloadXML()" v-if="!is_new">XML</CButton>
              <CButton color="danger" size="sm" @click="downloadPDF()" v-if="!is_new">PDF</CButton>
              <CButton color="danger" size="sm" @click="previewPDF()" v-if="is_new && concepts.length > 0">Vista Previa</CButton>
              <CButton color="info" size="sm" @click="sendInvoiceModa()" v-if="!is_new">Enviar por Correo</CButton>
              <CButton color="info" size="sm" @click="storeInvoice()" v-if="is_new && concepts.length > 0">Emitir Factura</CButton>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CancelationModal ref="cancelModal" @delete="cancelProcess"></CancelationModal>
    <EmailModal ref="emailModal" @send="sendInvoice"></EmailModal>
  </div>
</template>

<script>
import ConceptsTable from './ConceptsTable.vue'
import RelationsTable from './RelationsTable.vue'
import ReceptorModal from './modals/ReceptorModal.vue'
import InformationModal from './modals/InformationModal.vue'
import EmailModal from './modals/EmailModal.vue'
import CancelationModal from './modals/CancelationModal.vue'
import ConceptModal from './modals/ConceptModal.vue'
import RelationModal from './modals/RelationModal.vue'
import DiscountModal from './modals/DiscountModal.vue'
import ws from '../../services/invoices';
import serials_ws from '../../services/serials';
import products_ws from '../../services/products';
import account_ws from '../../services/account';
import general_ws from '../../services/general';
import store from '../../store'

import LocalTaxesTable from './addendas/LocalTaxesTable.vue'
import LocalTaxModal from './addendas/LocalTaxModal.vue'

import moment from 'moment';
import moment_timezone from 'moment-timezone'
import { computed } from 'vue'

moment.tz.setDefault('America/Mexico_city')

let today = new moment();
today = new moment(today.format("YYYY-MM-DD HH:m:ss"));

export default {
  name: 'InvoicesTable',
  components: { ConceptsTable, RelationsTable, LocalTaxesTable, ReceptorModal, InformationModal, ConceptModal, RelationModal, LocalTaxModal, EmailModal, CancelationModal, DiscountModal },
  props: {
    
  },
  data() {
    return {
      id: 0,
      option: 'draft',
      cfdi_id: 0,
      cfdi: false,
      is_new: true,
      blocked: false,
      has_relations: false,
      has_discount: false,
      products: [],
      invoice: {
        id: 0,
        emisor: {
          rfc: '{rfc}',
          name: '{name}',
          regimen: {
            code: '{regimen_code}',
            name: '{regimen_name}'
          },
          zipcode: '{zipcode}'
        },
        receptor: {
          customer_id: 0,
          rfc: 'XAXX010101000',
          name: 'PÚBLICO EN GENERAL',
          regimen: {
            code: '616',
            name: 'SIN OBLIGACIONES FISCALES'
          },
          uso_cfdi: {
            code: 'S01',
            name: 'SIN EFECTOS FISCALES'
          },
          identifier: '',
          street: '',
          exterior: '',
          interior: '',
          colony: '',
          municipality: '',
          state: 'JAL',
          country: 'MEX',
          zipcode: '46600',
          is_global: false,
          periodicity_global: '04',
          year_global: moment().format("YYYY"),
          month_global: moment().format("MM")
        },
        information: {
          document_type: 'I',
          serial: 'F',
          folio: 0,
          uuid: '',
          date: today,
          payment_form: {
            code: '99',
            name: 'Por Definir'
          },
          payment_method: {
            code: 'PPD',
            name: 'Pago en Parcialidades o Diferido'
          },
          currency: {
            code: 'MXN',
            name: 'Peso Mexicano'
          },
          exchange_rate: 1.0000,
          comments: ''
        },
        canceled: 0,
        cancelacion_status: "",
        has_discount: false
      },
      concepts: [],
      relations: [],
      relationDisabled: false,
      relation_type: "04",
      subtotal: 0,
      discounts: 0,
      transfered_taxes: 0,
      withheld_taxes: 0,
      total: 0,
      letters: '',
      toasts: [],
      addendas: {
        local_taxes: false,
        ine: false
      },
      has_local_taxes: false,
      local_taxes: [],
      has_ine: false,
    }
  },
  mounted: async function() {
    this.loading();
    this.id = await this.$route.params.id;
    this.option = await this.$route.params.option;

    if(this.id > 0){
      this.is_new = false;

      if(this.option == "clone"){
        this.is_new = true;
      }
    }

    if(!this.is_new && this.relations.length > 0){
      this.has_relations = true;
    }

    let response1 = await account_ws.getFiscalInfo(); 

    if(response1.type == "success"){
      this.invoice.emisor.rfc = response1.data.rfc.toUpperCase();
      this.invoice.emisor.name = response1.data.fiscal_name.toUpperCase();
      this.invoice.emisor.regimen = response1.data.regimen;
      this.invoice.emisor.zipcode = response1.data.zipcode;
      this.invoice.receptor.zipcode = response1.data.zipcode;
    }

    let response2 = await serials_ws.getDefault("income"); 

    if(response2.type == "success"){
      let serial = response2.data;

      this.invoice.information.serial = serial.serial;
      this.invoice.information.folio = serial.current;
      this.invoice.information.serial_id = serial.id;
    }

    if(this.id > 0 && this.option != "clone"){
      this.showInvoice();
    }
    else{
      this.is_new = true;

      if(this.id > 0 && this.option == "clone"){
        this.loadInvoice();
      }

      this.loaded();
    }

    let response3 = await products_ws.get(); 

    if(response3.type == "success"){
      this.products = response3.data;
    }

    //Validando el complemento de Impuestos Locales
    let response4 = await general_ws.hasAddenda("local_taxes"); 

    if(response4.type == "success"){
      this.addendas.local_taxes = response4.data;
    }

    response4 = await general_ws.hasAddenda("ine"); 

    if(response4.type == "success"){
      this.addendas.ine = response4.data;
    }
  },
  methods: {
    enableRelations () {
      this.has_relations = true;
    },
    disableRelations () {
      this.has_relations = false;
    },
    enableLocalTaxes () {
      this.has_local_taxes = true;
    },
    disableLocalTaxes () {
      this.has_local_taxes = false;
    },
    receptorModal (data) {
      this.$refs.receptorModal.openModal(data);
    },
    informationModal (data) {
      this.$refs.informationModal.openModal(data);
    },
    addConceptModal (data) {
      this.$refs.conceptModal.addConceptModal(data);
    },
    addRelationModal (rfc, data) {
      this.$refs.relationModal.addRelationModal(rfc, data);
    },
    addLocalTaxModal (data) {
      this.$refs.localTaxModal.addLocalTaxModal(data);
    },
    addDiscountModal () {
      this.$refs.discountModal.addDiscountModal(this.subtotal, this.total);
    },
    async refreshConcepts (items){
      this.updateTotals();
    },
    async refreshRelations (items){
      this.relations = await items;
    },
    async refreshLocalTaxes  (items){
      this.local_taxes = await items;
    },
    async loadInvoice (){
      this.loading();

      let response = await ws.show(this.id);

      this.invoice.id = this.id;

      if(response.type == "success"){
        let data = response.data;

        this.invoice.emisor.rfc = data.emisor.rfc.toUpperCase();
        this.invoice.emisor.name = data.emisor.nombre.toUpperCase();
        this.invoice.emisor.regimen.code = data.emisor.regimen.code;
        this.invoice.emisor.regimen.name = data.emisor.regimen.name;

        this.invoice.receptor.rfc = data.receptor.rfc.toUpperCase();
        this.invoice.receptor.name = data.receptor.nombre.toUpperCase();
        this.invoice.receptor.regimen.code = data.receptor.regimen.code;
        this.invoice.receptor.regimen.name = data.receptor.regimen.name;
        this.invoice.receptor.identifier = data.receptor.num_reg_id_trib;
        this.invoice.receptor.zipcode = data.receptor.domicilio_fiscal;
        
        this.invoice.receptor.street = data.receptor.street;
        this.invoice.receptor.interior = data.receptor.interior;
        this.invoice.receptor.exterior = data.receptor.exterior;
        this.invoice.receptor.colony = data.receptor.colony;
        this.invoice.receptor.municipality = data.receptor.municipality;
        this.invoice.receptor.state = data.receptor.state;
        this.invoice.receptor.country = data.receptor.residencia_fiscal;
        this.invoice.receptor.customer_id = data.customer_id;

        this.invoice.email = response.data.email;

        this.invoice.receptor.uso_cfdi = {
          code : data.uso_cfdi.code,
          name: data.uso_cfdi.name
        }
        
        this.invoice.information.currency = {
          code: data.currency,
          exchange_rate: data.exchange_rate
        };

        this.invoice.information.payment_form = data.payment_form;
        this.invoice.information.payment_method = data.payment_method;
        this.invoice.information.comments = data.comments;

        this.subtotal = 0;
        this.discounts = 0;
        this.transfered_taxes = 0;
        this.withheld_taxes = 0;
        this.total = 0;

        data.conceptos.forEach(item => {
            let concept = {
              sat_code: item.clave_prod_serv, 
              quantity: item.cantidad, 
              unit: {
                unit_code: item.clave_unidad, 
                unit_name: item.unidad
              }, 
              description: item.descripcion, 
              price: item.valor_unitario, 
              discount: item.descuento,
              tax_id: item.tax_id,
              taxes: item.taxes, 
              total: item.cantidad * item.valor_unitario
            };

            this.subtotal += concept.total * 1;
            this.discounts += concept.discount * 1;

            item.taxes.forEach(tax => {
              if(tax.type == "TRASLADO"){
                this.transfered_taxes += (concept.total - concept.discount) * tax.rate;
              }
              else{
                this.withheld_taxes += (concept.total - concept.discount) * tax.rate;
              }
            });

            this.concepts.push(concept); 
        });

        this.has_local_taxes = data.has_local_taxes;
        this.local_taxes = data.local_taxes;

        if(this.has_local_taxes){
           this.local_taxes.forEach(local_tax => {
              local_tax.rate /= 100;

              if(local_tax.type == "TRASLADO"){
                this.transfered_taxes += local_tax.tax_import * 1;
              }
              else{
                this.withheld_taxes += local_tax.tax_import * 1;
              }
            });
        }

        this.total = this.subtotal - this.discounts + this.transfered_taxes - this.withheld_taxes;
        this.letters = data.total_letra; 

        this.loaded(); 
      }
    },
    async showInvoice (){
      this.loading();

      let response = await ws.show(this.id);

      this.invoice.id = this.id; 

      if(response.type == "success"){
        let data = response.data;

        this.invoice.cfdi_id = response.data.cfdi_id;
        this.invoice.cfdi = response.data.cfdi;
        this.invoice.uuid = response.data.uuid; 
        this.invoice.canceled = response.data.canceled;
        this.invoice.cancelacion_status = response.data.cancelacion_status;

        this.invoice.emisor.rfc = data.emisor.rfc.toUpperCase();
        this.invoice.emisor.name = data.emisor.nombre.toUpperCase();
        this.invoice.emisor.regimen.code = data.emisor.regimen.code;
        this.invoice.emisor.regimen.name = data.emisor.regimen.name;

        this.invoice.receptor.rfc = data.receptor.rfc.toUpperCase();
        this.invoice.receptor.name = data.receptor.nombre.toUpperCase();
        this.invoice.receptor.regimen.code = data.receptor.regimen.code;
        this.invoice.receptor.regimen.name = data.receptor.regimen.name;
        this.invoice.receptor.identifier = data.receptor.num_reg_id_trib;

        this.invoice.email = response.data.email;

        this.invoice.receptor.uso_cfdi = {
          code : data.uso_cfdi.code,
          name: data.uso_cfdi.name
        }

        this.invoice.information.serial = data.cfdi.serie;
        this.invoice.information.folio = data.cfdi.folio;
        this.invoice.information.uuid = data.uuid;
        this.invoice.information.date = data.date;
        
        this.invoice.information.currency = {
          code: data.currency,
          exchange_rate: data.exchange_rate
        };

        this.invoice.information.payment_form = data.payment_form;
        this.invoice.information.payment_method = data.payment_method;
        this.invoice.information.comments = data.comments;

        this.subtotal = data.subtotal;
        this.discounts = data.discounts;
        this.transfered_taxes = data.transfered_taxes;
        this.withheld_taxes = data.withheld_taxes;
        this.total = data.total;

        data.conceptos.forEach(item => {
            let concept = {
              sat_code: item.clave_prod_serv, 
              quantity: item.cantidad, 
              unit: {
                unit_code: item.clave_unidad, 
                unit_name: item.unidad
              }, 
              description: item.descripcion, 
              price: item.valor_unitario,
              discount: item.descuento, 
              tax_id: item.tax_id,
              taxes: item.taxes, 
              total: item.cantidad * item.valor_unitario
            };

            this.concepts.push(concept); 
        });

        if(data.relaciones){
          this.has_relations = true;
          
          data.relaciones.forEach(relation => {
            relation = relation.uuid;
          });

          this.relations = data.relaciones;
        }

        this.has_local_taxes = data.has_local_taxes;
        this.local_taxes = data.local_taxes;

        this.letters = data.total_letra; 

        this.loaded();
      }
    },
    async updateReceptor(data) {
      this.invoice.receptor.customer_id = data.customer_id;
      this.invoice.receptor.rfc = data.rfc;
      this.invoice.receptor.name = data.name;
      
      this.invoice.receptor.uso_cfdi.code = data.uso_cfdi_code;
      this.invoice.receptor.uso_cfdi.name = data.uso_cfdi_name;

      this.invoice.receptor.regimen.code = data.regimen_code;
      this.invoice.receptor.regimen.name = data.regimen_name;

      this.invoice.receptor.identifier = data.identifier;

      this.invoice.receptor.street = data.street;
      this.invoice.receptor.exterior = data.exterior;
      this.invoice.receptor.interior = data.interior;
      this.invoice.receptor.colony = data.colony;
      this.invoice.receptor.municipality = data.municipality;
      this.invoice.receptor.state = data.state;
      this.invoice.receptor.country = data.country;
      this.invoice.receptor.zipcode = data.zipcode;

      this.invoice.receptor.is_global = data.is_global;
      this.invoice.receptor.periodicity_global = data.periodicity_global;
      this.invoice.receptor.year_global = data.year_global;
      this.invoice.receptor.month_global = data.month_global;
    },
    async updateInformation(data) {
      this.invoice.information.serial = data.serial;
      this.invoice.information.folio = data.folio;
      this.invoice.information.serial_id = data.serial_id;
      
      this.invoice.information.payment_form.code = data.payment_form_code;
      this.invoice.information.payment_form.name = data.payment_form_name;
      this.invoice.information.payment_method.code = data.payment_method_code;
      this.invoice.information.payment_method.name = data.payment_method_name;

      this.invoice.information.currency.code = data.currency_code;
      this.invoice.information.currency.name = data.currency_name;
      this.invoice.information.currency.exchange_rate = data.exchange_rate;

      this.invoice.information.comments = data.comments;

      let date = moment(data.date);

      this.invoice.information.date = date;
    },
    async addConcept(tab, data, taxes) {
      data.quantity = data.quantity.replaceAll(',', '');
      data.price = data.price.replaceAll(',', '');
      data.discount = data.discount.replaceAll(',', '');
      data.import = data.import.replaceAll(',', '');

      if(data.discount > 0){
        this.has_discount = true;
      }

      if(tab == "basic"){
        let product = await this.$findElementById(this.products, data.concept);

        let concept = {
          sat_code: product.sat_code, 
          quantity: data.quantity, 
          unit: {
            unit_code: product.unit_code, 
            unit_name: product.unit_name.substring(0, 20)
          }, 
          description: product.name, 
          price: data.price, 
          discount: data.discount, 
          tax_id: data.taxes,
          taxes: taxes.taxes, 
          total: data.import
        };

        this.concepts.push(concept);
      }
      else{
        let concept = {
          sat_code: data.fiscal_product, 
          quantity: data.quantity, 
          unit: {
            unit_code: data.unit_code,
            unit_name: data.unit_name
          }, 
          description: data.concept, 
          price: data.price, 
          discount: data.discount, 
          tax_id: data.taxes,
          taxes: taxes.taxes, 
          total: data.import
        };

        this.concepts.push(concept);
      }

      this.updateTotals();
    },
    async addRelation(data) {
      
      this.relations.push(data.uuid);
      this.relation_type = data.relation_type;

      this.relationDisabled = true;
    },
    async addLocalTax(data) {
      this.local_taxes.push(data);

      this.updateTotals();
    },
    async addDiscount(data) {
      data.amount = data.amount+"";
      data.amount = data.amount.replaceAll(',', '');

      if(data.amount > 0){
        this.has_discount = true;
      }

      if(data.type == "SUBTOTAL"){
        let subtotal = this.subtotal;
        let total_discount = this.subtotal - data.amount;

        this.concepts.forEach(item => {
          let factor = item.total / subtotal;
          let discount = total_discount * factor;

          item.discount = discount;
        });
      }
      else{
        let total = this.total;
        let total_discount = this.total - data.amount;

        let avg = total_discount * 100 / this.total;

        this.concepts.forEach(item => {
          let discount = item.total * avg / 100;

          item.discount = discount;
        });
      }
        
        this.updateTotals();
    },
    async deleteDiscount() {
      this.concepts.forEach(item => {
        item.discount = this.$options.filters.decimals(0,4);
      });

      this.has_discount = false;
        
      this.updateTotals();
    },
    deleteConcept(index) {
      this.concepts.splice(index, 1);

      this.updateTotals();
    },
    deleteRelation(index) {
      this.relations.splice(index, 1);

      if(this.relations.length == 0){
        this.relationDisabled = false;
      }
    },
    deleteLocalTax(index) {
      this.local_taxes.splice(index, 1);

      this.updateTotals();
    },
    clearInvoice() {
      this.concepts = [];

      this.updateTotals();
    },
    updateTotals(){
      this.subtotal = 0;
      this.discounts = 0;
      this.transfered_taxes = 0;
      this.withheld_taxes = 0;
      this.total = 0;

      if(this.concepts !== undefined){
        this.concepts.forEach(item => {
          this.subtotal += item.total * 1;
          this.discounts += item.discount * 1;

          if(item.taxes !== undefined){
            item.taxes.forEach(tax => {
              if(tax.type == "TRASLADO"){
                this.transfered_taxes += (item.quantity * item.price - item.discount) * tax.rate;
              }
              else{
                this.withheld_taxes += (item.quantity * item.price - item.discount) * tax.rate;
              }
            });
          }
        });
      }

      if(this.discounts > 0){
        this.invoice.has_discount = true;
        this.has_discount = true;
      }
      else{
        this.invoice.has_discount = false;
        this.has_discount = false;
      }

      if(this.local_taxes !== undefined){
        this.local_taxes.forEach(local_tax => {
          let subtotal = this.subtotal;
          if(this.has_discount || this.discounts > 0){
            if(local_tax.apply_to && local_tax.apply_to == 'subtotal_with_discounts'){
              subtotal = this.subtotal - this.discounts;
            }
          }

          local_tax.tax_import = subtotal * local_tax.rate;
          
          if(local_tax.type == "TRASLADO"){
            this.transfered_taxes += subtotal * local_tax.rate;
          }
          else{
            this.withheld_taxes += subtotal * local_tax.rate;
          }
        });
      }

      this.total = this.subtotal - this.discounts + this.transfered_taxes - this.withheld_taxes;
      this.letters = ""; 
    },
    async storeInvoice() {
      if(this.blocked){
        return;
      }

      this.blocked = true;

      this.loading();

      let exchange_rate = this.invoice.information.exchange_rate;

      if(this.invoice.information.currency.exchange_rate !== undefined){
        exchange_rate = this.invoice.information.currency.exchange_rate;
      }
      else{
        if(exchange_rate === undefined){
          exchange_rate = 1.000;
        }
      }

      let data = {
        customer_id: this.invoice.receptor.customer_id,
        receptor: {
          rfc: this.invoice.receptor.rfc,
          name: this.invoice.receptor.name,
          identifier: this.invoice.receptor.identifier,
          zipcode: this.invoice.receptor.zipcode,
          regimen: this.invoice.receptor.regimen.code,
          street: this.invoice.receptor.street,
          exterior: this.invoice.receptor.exterior,
          interior: this.invoice.receptor.interior,
          colony: this.invoice.receptor.colony,
          municipality: this.invoice.receptor.municipality,
          state: this.invoice.receptor.state,
          is_global: this.invoice.receptor.is_global,
          periodicity_global: this.invoice.receptor.periodicity_global,
          year_global: this.invoice.receptor.year_global,
          month_global: this.invoice.receptor.month_global,
          country: this.invoice.receptor.country
        },
        uso_cfdi: this.invoice.receptor.uso_cfdi.code,
        version: 4.0,
        date: this.invoice.information.date.format("YYYY-MM-DD HH:m:ss"),
        serial_id: this.invoice.information.serial_id,
        currency: this.invoice.information.currency,
        exchange_rate: exchange_rate,
        payment_method: this.invoice.information.payment_method,
        payment_form: this.invoice.information.payment_form,
        concepts: this.concepts,
        relations: this.has_relations ? this.relations : [],
        relation_type: this.has_relations ? this.relation_type : "04",
        has_discount: this.invoice.has_discount,
        comments: this.invoice.information.comments,
        has_local_taxes: this.has_local_taxes,
        local_taxes: this.local_taxes
      }

      let response = await ws.store(data);

      if(response.type == "success"){
        this.$emit("refresh", response.data );

        window.location.href = "/#/invoices/"+response.data.id+"/view";
      }
      else{
        this.loaded();
      }

      this.showToast(response.type, response.message);
      this.blocked = false;
    },
    async sendInvoiceModa() {
      this.$refs.emailModal.show(this.invoice, 'I');
    },
    async sendInvoice(invoice, data) {
      this.loading();
      let response = await ws.send(invoice.id, data);

      this.loaded();
      this.showToast(response.type, response.message);
    },
    cloneInvoice () {
      window.location.href = "/#/invoices/"+this.invoice.id+"/clone";
    },
    async cancelInvoice() {
      this.$refs.cancelModal.show("Cancelar CFDI", this.invoice);
    },
    async cancelProcess(id, data) {
      this.loading();
      let response = await ws.cancel(id, data);

      if(response.type == "success"){
        this.clearInvoice();
        this.showInvoice();
      }
      
      this.showToast(response.type, response.message);
      this.loaded();
    },
    async downloadXML(){
      ws.downloadXML(this.id);
    },
    async downloadPDF(){
      ws.downloadPDF(this.id);
    },
    async previewPDF(){
      let data = {
        customer_id: this.invoice.receptor.customer_id,
        receptor: {
          rfc: this.invoice.receptor.rfc,
          name: this.invoice.receptor.name,
          identifier: this.invoice.receptor.identifier,
          zipcode: this.invoice.receptor.zipcode,
          regimen: this.invoice.receptor.regimen.code,
          street: this.invoice.receptor.street,
          exterior: this.invoice.receptor.exterior,
          interior: this.invoice.receptor.interior,
          colony: this.invoice.receptor.colony,
          municipality: this.invoice.receptor.municipality,
          state: this.invoice.receptor.state,
          country: this.invoice.receptor.country
        },
        uso_cfdi: this.invoice.receptor.uso_cfdi.code,
        version: 4.0,
        date: this.invoice.information.date.format("YYYY-MM-DD HH:m:ss"),
        serial_id: this.invoice.information.serial_id,
        currency: this.invoice.information.currency,
        exchange_rate: this.invoice.information.exchange_rate,
        payment_method: this.invoice.information.payment_method,
        payment_form: this.invoice.information.payment_form,
        comments: this.invoice.information.comments,
        concepts: this.concepts,
        relations: this.has_relations ? this.relations : [],
        relation_type: this.has_relations ? this.relation_type : "04",
        has_discount: this.invoice.has_discount,
        has_local_taxes: this.has_local_taxes,
        local_taxes: this.local_taxes
      }

      let response = await ws.preview(data);

      if(response.type != "success"){
        this.showToast(response.type, response.message);
      }
    },
    async downloadCancelationPDF(){
      ws.downloadCancelationPDF(this.id);
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  },
  computed: {
    has_access () {
      return store.state.stamps <= 0 || store.state.deadline_days <= 0 ? false : true;
    },
  }
}
</script>
