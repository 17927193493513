<template>
    <CModal
      :title="title"
      :color="color"
      :show.sync="discountModal"
    >
      <CRow>
        <CCol md="12">
          <CForm>
            <CRow>
              <CCol md="6">
                <CSelect
                  label="Aplicar a:"
                  :value.sync="$v.form.type.$model"
                  :options="typeOptions"
                  @change="changeType"
                />
              </CCol>
              <CCol md="6">
                <CInput
                  label="Monto Final:"
                  :lazy="false"
                  :isValid="checkIfValid('amount')"
                  :value.sync="$v.form.amount.$model"
                  placeholder=""
                  invalidFeedback="Este campo es requerido y debe ser valor mayor que cero."
                />
              </CCol>
            </CRow>
          </CForm>
        </CCol>
      </CRow>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton v-if="has_discount" @click="destroy" color="danger" style="float: left;">Limpiar</CButton>
          <CButton @click="store" :color="color">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, maxLength, integer, minValue, email} from "vuelidate/lib/validators"

import moment from 'moment';

const today = new moment();
const today2 = new moment();

export default { 
  name: 'DiscountModal',
  props: {
    has_discount: false
  },
  components: {
  },
  data () {
    return {
      discountModal: false,
      title: "Aplicar Descuento", 
      color: "info",
      isEdit: false,
      tab: "basic",
      form: {},
      submitted: false,
      subtotal: 0.00,
      total: 0.00,
      typeOptions: []
    }
  },
  mounted: async function() {
    this.typeOptions.push({label: 'SUBTOTAL', value: 'SUBTOTAL'});
    this.typeOptions.push({label: 'TOTAL', value: 'TOTAL'});
  },
  methods: {
    async changeType () {
      if(this.form.type == "SUBTOTAL"){
        this.form.amount = this.$options.filters.decimals(this.subtotal,2);
      }
      else{
        this.form.amount = this.$options.filters.decimals(this.total,2);
      }
    },
    addDiscountModal (subtotal, total) {
      this.discountModal = true;
      this.color = "info";
      this.title = 'Aplicar Descuento';
      this.isEdit = false;
      this.submitted = false;

      this.subtotal = subtotal;
      this.total = total;

      this.form = {
        type: 'SUBTOTAL',
        amount: this.subtotal
      };
    },
    async store () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        await this.$emit("deleteDiscount");
        
        await this.$emit("addDiscount", this.form);

        this.closeModal();
      }
    },
    async destroy () {
      this.$emit("deleteDiscount");

      this.closeModal();
    },
    closeModal () {
      this.discountModal = false;

      this.submitted = false
      this.$v.$reset()
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName];

      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid)
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      type: false,
      amount: {
        required,
        minValue: minValue(0.01)
      }
    }
  },
}
</script>