<template>
  <div>
    <CDataTable
      :hover="hover"
      :striped="striped"
      :border="border"
      :small="small"
      :fixed="fixed"
      :items="items"
      :fields="is_new ? fields_with_actions : fields"
      :items-per-page="small ? 10 : 5"
      :dark="dark"
      pagination
    >
      <template #type="{item}"><td class="w200 text-center">{{ item.type }}</td></template>
      <template #rate="{item}"><td class="w200 text-center">{{ item.tax_import ? item.rate : item.rate * 100 | decimal }} %</td></template>
      <template #tax_import="{item}"><td class="w200 text-right">$ {{ item.tax_import ? item.tax_import : item.rate * (item.apply_to == 'subtotal_with_discounts' ? subtotal - discounts : subtotal) | decimal }}</td></template>
      <template #actions="{item, index}">
        <td class="table_actions text-center">
          <CButton color="danger" size="sm" @click="deleteModal(item, index)"><CIcon name="cil-trash"/></CButton>
        </td>
      </template>
    </CDataTable>

    <RemoveModal ref="deleteModal" @delete="deleteProcess"></RemoveModal>
  </div>
</template>

<script>
import RemoveModal from '../../global/RemoveModal.vue'
import ws from '../../../services/invoices';
import store from '../../../store'

export default {
  name: 'LocalTaxesTable',
  components: { RemoveModal },
  props: {
    is_new: true,
    items: Array,
    subtotal: Number,
    discounts: Number,
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean,
  },
  data() {
    return {
      fields: [
        {key: 'name', label: 'Impuesto'},
        {key: 'type', label: 'Tipo'},
        {key: 'rate', label: 'Porcentaje'},
        {key: 'tax_import', label: 'Importe'}
      ],
      fields_with_actions: [
        {key: 'name', label: 'Impuesto'},
        {key: 'type', label: 'Tipo'},
        {key: 'rate', label: 'Porcentaje'},
        {key: 'tax_import', label: 'Importe'},
        {key: 'actions', label: 'Acciones', class: 'actions' }
      ],
      toasts: []
    }
  },
  methods: {
    deleteModal (item, index) {
      this.$refs.deleteModal.show("Eliminar Impuesto", index, item.name);
    },
    async deleteProcess(index) {
      this.$emit("deleteLocalTax", index);
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
